import 'arrive';
import { getConfig } from '@fec/assets/js/utils/config';

const HOOK_SELECTOR = '[data-js-plugin]';
let loadedPlugins = [];
let config = getConfig();

export function init(representation = 'web') {
  let loadOnInit = [];
  $(HOOK_SELECTOR).each((idx, target) => {
    loadOnInit.push(...$(target).data('js-plugin').split(','));
  });
  loadPlugins(loadOnInit, representation);
  loadedPlugins = loadOnInit;

  // load plugins for content that is added to DOM after document.ready (via ajax, websocket, ..)
  document.arrive(HOOK_SELECTOR, (target) => {
    let loadOnArrive = $(target).data('js-plugin').split(',');
    loadOnArrive = loadOnArrive.filter((p) => {
      return !loadedPlugins.includes(p);
    });
    loadPlugins(loadOnArrive, representation);
    loadedPlugins.concat(loadOnArrive);
  });
}

function loadPlugins(plugins, representation) {
  // Add janrain dependency if comments are present or email verification page is called
  if (plugins.includes('janrain')) {
    import(
      /* webpackChunkName: 'srf-comments-login' */ './srf-janrain.js'
    ).then((module) => runPlugin(module));
  }

  if (plugins.includes('poll')) {
    import(/* webpackChunkName: 'srf-poll' */ './srf-poll.js').then((module) =>
      runPlugin(module),
    );
    import(
      /* webpackChunkName: 'srf-ratingstars' */ './srf-ratingstars.js'
    ).then((module) => runPlugin(module));
  }

  if (plugins.includes('liveticker')) {
    import(/* webpackChunkName: 'srf-liveticker' */ './srf-liveticker.js').then(
      (module) => runPlugin(module),
    );
  }

  if (plugins.includes('image_gallery') && !plugins.includes('video_gallery')) {
    import(
      /* webpackChunkName: 'srf-carousel' */ '@fec/frontend/modules/content/image-carousel/image-carousel.js'
    ).then((module) => {
      runPlugin(module);

      if (representation === 'web') {
        import(
          /* webpackChunkName: 'srf-carousel' */ '../../article/js/classes/SrfCarousel.js'
        ).then((module) => runPlugin(module));
      } else {
        import(
          /* webpackChunkName: 'srf-carousel' */ './classes/SrfCarousel.js'
        ).then((module) => runPlugin(module));
      }
    });
  }

  if (plugins.includes('video_gallery')) {
    import(
      /* webpackChunkName: 'srf-carousel' */ '@fec/frontend/modules/content/image-carousel/image-carousel.js'
    ).then((module) => {
      runPlugin(module);
      import(
        /* webpackChunkName: 'srf-video-gallery' */ '../../webview/js/srf-video-gallery.js'
      ).then((module) => runPlugin(module));
    });
  }

  if (plugins.includes('audio_gallery')) {
    import(
      /* webpackChunkName: 'srf-audio-gallery' */ './srf-audio-gallery.js'
    ).then((module) => runPlugin(module));
  }

  if (plugins.includes('image_slider')) {
    import(
      /* webpackChunkName: 'srf-image-slider' */ '@fec/frontend/modules/content/image-slider/image-slider.js'
    ).then((module) => runPlugin(module));
  }

  if (plugins.includes('embed_listmodule')) {
    import(
      /* webpackChunkName: 'srf-listmodule' */ 'fef-legacy/assets/js/srf-listmodule.js'
    ).then((module) => runPlugin(module));
  }

  if (plugins.includes('vote_map')) {
    import(/* webpackChunkName: 'srf-polis' */ './srf-polis.js').then(
      (module) => runPlugin(module),
    );
  }

  if (plugins.includes('pseudo_links') && representation === 'web') {
    import(
      /* webpackChunkName: 'srf-pseudo-links' */ './srf-pseudo-links.js'
    ).then((module) => runPlugin(module));
  }

  if (plugins.includes('chflashmap')) {
    import(
      /* webpackChunkName: 'srf-chmap' */ 'fef-legacy/assets/js/srf-chmap.js'
    ).then((module) => runPlugin(module));
  }

  if (
    plugins.includes('news_chart_serial') ||
    plugins.includes('news_chart_pie')
  ) {
    import(/* webpackChunkName: 'srf-newschart' */ './srf-charts.js').then(
      (module) => runPlugin(module),
    );
  }

  if (plugins.includes('asset-availability')) {
    import(
      /* webpackChunkName: 'asset-availability' */ './srf-asset-availability.js'
    ).then((module) => runPlugin(module));
  }

  if (plugins.includes('twitter-embed')) {
    import(/* webpackChunkName: 'srf-twitter' */ './srf-twitter.js').then(
      (module) => runPlugin(module),
    );
  }

  if (plugins.includes('facebook-embed')) {
    loadExternalJs(
      'https://connect.facebook.net/de_DE/sdk.js#xfbml=1&version=v9.0',
    );
  }

  if (plugins.includes('meteo-collections')) {
    import(
      /* webpackChunkName: 'srf-meteo-collections' */ '../../meteo/css/main.scss'
    ).then(() => {
      import(
        /* webpackChunkName: 'srf-meteo-collections' */ '../../page/js/meteo.js'
      ).then((module) => runPlugin(module));
    });
  }

  if (plugins.includes('radio-program')) {
    import(
      /* webpackChunkName: 'srf-radio-program' */ '../../radioProgram/js/index.js'
    ).then((module) => runPlugin(module));
  }

  if (plugins.includes('srf-song-log')) {
    import(
      /* webpackChunkName: 'srf-song-log' */ '../../songLog/js/App.js'
    ).then((module) => runPlugin(module));
  }

  if (plugins.includes('srf-rubric-search')) {
    import(
      /* webpackChunkName: 'srf-rubric-search' */ '../../search/js/index.js'
    ).then((module) => runPlugin(module));
  }

  if (plugins.includes('woody')) {
    import(
      /* webpackChunkName: 'srf-woody-ticker' */ '../../ticker/index.js'
    ).then((module) => runPlugin(module));
  }

  if (plugins.includes('app-promo-banner')) {
    import(
      /* webpackChunkName: 'app-promo-banner-styles' */ '@fec/frontend/snowflakes/ads/app-promo-banner/app-promo-banner.scss'
    ).then(() =>
      import(
        /* webpackChunkName: 'app-promo-banner-js' */ '@fec/frontend/snowflakes/ads/app-promo-banner/app-promo-banner.js'
      ).then((module) => runPlugin(module)),
    );
  }

  if (plugins.includes('a2z')) {
    import(/* webpackChunkName: 'srf-a2z' */ '../../page/js/a2z.js').then(
      (module) => runPlugin(module),
    );
  }

  if (config.REPRESENTATION === 'web') {
    import(
      /* webpackChunkName: 'srf-webpush' */ '../../common/js/webpush.js'
    ).then((module) => runPlugin(module));
  }

  if (plugins.includes('error-page')) {
    import(
      /* webpackChunkName: 'srf-error-page' */ '../../page/js/srf-error-page.js'
    ).then((module) => runPlugin(module));
  }

  if (plugins.includes('formio-form')) {
    import(
      /* webpackChunkName: 'srf-formio-form' */ '../../formio/js/formio-form.js'
    ).then((module) => runPlugin(module));
  }

  if (plugins.includes('audio-episodes')) {
    import(
      /* webpackChunkName: 'srf-audio-episodes' */ '../../page/css/audio.scss'
    ).then(() => {
      import(
        /* webpackChunkName: 'srf-audio-episodes' */ '../../page/js/audio.js'
      ).then((module) => runPlugin(module));
    });
  }

  if (plugins.includes('letterbox')) {
    import(
      /* webpackChunkName: "letterbox-web-css" */ '@srgssr/srgletterbox-web/dist/letterbox.css'
    ).then(() => {
      import(
        /* webpackChunkName: "letterbox-web-js" */ '@srgssr/srgletterbox-web/dist/letterbox.js'
      ).then((module) => {
        window.SRGLetterbox = module.default;
      });
    });
  }

  if (plugins.includes('radio-live-player')) {
    import(
      /* webpackChunkName: 'radio-live-player' */ '@fec/frontend/snowflakes/radio/radio-live-player/radio-live-player.js'
    ).then((module) => runPlugin(module));
  }

  if (plugins.includes('radio-content-header')) {
    import(
      /* webpackChunkName: 'radio-content-header' */ '@fec/frontend/snowflakes/radio/radio-content-header/radio-content-header.js'
    ).then((module) => runPlugin(module));
  }

  if (plugins.includes('marquee')) {
    import(
      /* webpackChunkName: 'marquee' */ '@fec/frontend/elements/wrappers/marquee/marquee.js'
    ).then((module) => runPlugin(module));
  }

  // Static item "resume_listening"
  if (plugins.includes('srf-resume-listening')) {
    import(
      /* webpackChunkName: 'srf-resume-listening' */ './srf-resume-listening.js'
    ).then((module) => runPlugin(module));
  }

  // embed ElectionWidget requires special handling for links in apps
  if (
    plugins.includes('srg-election-widget') &&
    config.REPRESENTATION === 'webview'
  ) {
    import(
      /* webpackChunkName: 'srg-election-widget-linkhandler' */ './srg-election-widget-linkhandler.js'
    ).then((module) => runPlugin(module));
  }

  if (plugins.includes('iframe-resize')) {
    import(
      /* webpackChunkName: 'srf-iframe-adjust' */ './srf-iframe-adjust.js'
    ).then((module) => runPlugin(module));
  }

  if (plugins.includes('promo-banner')) {
    import(
      /* webpackChunkName: 'srf-promo-banner' */ '@fec/frontend/snowflakes/article/promo-banner/promo-banner.js'
    ).then((module) => runPlugin(module));
  }

  if (plugins.includes('image-gallery')) {
    import(
      /* webpackChunkName: 'image-gallery-styles' */ '@fec/frontend/modules/content/image-gallery/image-gallery.scss'
    ).then(() =>
      import(
        /* webpackChunkName: 'image-gallery-js' */ '@fec/frontend/modules/content/image-gallery/image-gallery.js'
      ).then((module) => runPlugin(module)),
    );
  }

  if (plugins.includes('player-widget')) {
    import(
      /* webpackChunkName: 'srf-player-widget' */ '@fec/frontend-aron/modules/media/PlayerWidget/PlayerWidget.jsx'
    ).then((module) => runPlugin(module));
  }
}

function runPlugin(plugin) {
  // Each plugin must provide a init function
  if (plugin.init && typeof plugin.init === 'function') {
    plugin.init();
  }
}

function loadExternalJs(src) {
  let element = document.createElement('script');
  element.setAttribute('language', 'javascript');
  element.setAttribute('type', 'text/javascript');
  element.setAttribute('src', src);
  element.setAttribute('async', '1');
  document.body.appendChild(element);
}
