import { ModuleMeasurement } from './ModuleMeasurement';
import { DownloadMeasurement } from './DownloadMeasurement';
import { InteractionMeasurement } from './InteractionMeasurement';
import { SrgElectionWidgetTracking } from './SrgElectionWidgetTracking';
import { Webtrekk } from './Webtrekk';
import { Chartbeat } from './Chartbeat';
import { SurveyGizmo } from './SurveyGizmo';
import { SwissMediaDataHub } from './SwissMediaDataHub';
import { getConfig } from '@fec/assets/js/utils/config';
import { Tools } from './Tools';
import { ClickMeasurement } from './ClickMeasurement';
import $ from 'jquery';
import { UDPTracking } from './UDPTracking';
import { init as KameleoonInit } from './Kameleoon';
import {
  ACTIVATE_TRACKER,
  DISABLE_TRACKER,
  onEvent,
} from '@fec/assets/js/utils/event';

const CONFIG = getConfig();

const activateByTrackerId = (trackerId) => {
  switch (trackerId) {
    case 'kameleeon':
      try {
        // eslint-disable-next-line no-undef
        Kameleoon?.API.Core.enableLegalConsent('BOTH');
      } catch (e) {
        //no kameleoon found, seems to be good at hiding.
      }
      break;
    case 'webtrekk':
      if (isWeb() && CONFIG.ANALYTICS_WEBTREKK_VALUE_OBJECT) {
        let webtrekk = new Webtrekk(CONFIG.ANALYTICS_WEBTREKK_VALUE_OBJECT);
        webtrekk.sendInfo();
        // surveygizmo requires access via window
        window.srfWebtrekk = webtrekk;
        new ModuleMeasurement();
        new DownloadMeasurement(webtrekk);
        new InteractionMeasurement(webtrekk);
        new SrgElectionWidgetTracking(webtrekk);
        new ClickMeasurement();
      }
      break;
    case 'udp':
      if (CONFIG.ANALYTICS_UDP_VALUE_OBJECT) {
        if (isWeb()) {
          let udpTracking = new UDPTracking(CONFIG, new Tools());
          udpTracking.sendData(JSON.stringify(udpTracking.page_view));
        } else {
          new UDPTracking(CONFIG, new Tools());
        }
      }

      break;
    case 'SurveyGizmo':
      if (
        CONFIG.ANALYTICS_SURVEY_GIZMO_ENABLED &&
        CONFIG.ANALYTICS_WEBTREKK_SURVEY_GIZMO_VALUE_OBJECT
      ) {
        if (isWeb()) {
          let surveyGizmo = new SurveyGizmo();
          surveyGizmo.webtrekkSurveyGizmoValueObject =
            CONFIG.ANALYTICS_WEBTREKK_SURVEY_GIZMO_VALUE_OBJECT.params;
          surveyGizmo.webtrekkValueObject =
            CONFIG.ANALYTICS_WEBTREKK_VALUE_OBJECT;
          surveyGizmo.webtrekkClass = window.srfWebtrekk || {};
          surveyGizmo.surveyGizmoBeacon = CONFIG.ANALYTICS_SURVEY_GIZMO_BEACON;
          surveyGizmo.env = CONFIG.ENVIRONMENT;
          surveyGizmo.seoUrl = CONFIG.SEO_URL;
          surveyGizmo.init();
        } else if (
          CONFIG.ANALYTICS_SURVEY_GIZMO_ENABLED &&
          CONFIG.ANALYTICS_WEBTREKK_VALUE_OBJECT &&
          CONFIG.ANALYTICS_WEBTREKK_SURVEY_GIZMO_VALUE_OBJECT
        ) {
          let webtrekk = new Webtrekk(CONFIG.ANALYTICS_WEBTREKK_VALUE_OBJECT);
          let surveyGizmo = new SurveyGizmo();

          surveyGizmo.webtrekkSurveyGizmoValueObject =
            CONFIG.ANALYTICS_WEBTREKK_SURVEY_GIZMO_VALUE_OBJECT.params;
          surveyGizmo.webtrekkValueObject =
            CONFIG.ANALYTICS_WEBTREKK_VALUE_OBJECT;
          surveyGizmo.surveyGizmoBeacon = CONFIG.ANALYTICS_SURVEY_GIZMO_BEACON;
          surveyGizmo.webtrekkClass = webtrekk;
          surveyGizmo.env = CONFIG.ENVIRONMENT;
          surveyGizmo.seoUrl = CONFIG.SEO_URL;

          let tools = new Tools();
          let isInitialized = false;

          if (tools.getData('sg_app_data') && isInitialized === false) {
            surveyGizmo.init();
          } else {
            const initSurveyGizmo = () => {
              if (tools.getData('sg_app_data') && isInitialized === false) {
                surveyGizmo.init();
              }
            };

            document.addEventListener('setDeviceInfo', initSurveyGizmo); // triggered from app via bridge
          }
        }
      }

      break;
    case 'comscore':
      if (isWeb()) {
        let fpid = 'undefined';

        if (
          document.cookie
            .split(';')
            .some((item) => item.trim().startsWith('cs_fpid='))
        ) {
          fpid = document.cookie
            .split('; ')
            .find((row) => row.startsWith('cs_fpid='))
            .split('=')[1];
        } else {
          fpid = [
            +new Date(),
            Math.floor(Math.random() * 99999999 + 1000000),
          ].join('_');
          setCookieForComscore('cs_fpid', fpid, 365);
        }
        window._comscore = window._comscore || [];
        window._comscore.push({
          c1: '2',
          c2: '6036016',
          mp_brand: CONFIG.BUSINESS_UNIT.toUpperCase(),
          ns_site: `${CONFIG.BUSINESS_UNIT.toLowerCase()}.ch`,
          mp_v: '*null',
          cs_fpid: fpid,
          mp_login: '3',
        });

        const smdh = new SwissMediaDataHub();
        smdh.config = CONFIG.ANALYTICS_SWISS_MEDIA_DATA_HUB;
        smdh.sendInfo();
      }
      break;

    case 'chartbeat':
      if (CONFIG.ANALYTICS_WEBTREKK_VALUE_OBJECT) {
        new Chartbeat(
          CONFIG.ANALYTICS_WEBTREKK_VALUE_OBJECT,
          CONFIG.BUSINESS_UNIT,
          CONFIG.SEO_URL,
        );
        if (CONFIG.BUSINESS_UNIT === 'srf') {
          const tools = new Tools();
          tools.loadExternalJs('//static.chartbeat.com/js/chartbeat_mab.js');
        }
      }
      break;
  }
};

const deactivateByTrackerId = (trackerId) => {
  switch (trackerId) {
    case 'kameleeon':
      try {
        // eslint-disable-next-line no-undef
        Kameleoon?.API.Core.disableLegalConsent('BOTH');
      } catch (e) {
        //no kameleoon found, seems to be good at hiding.
      }
      break;
  }
};

const isWeb = () => CONFIG.REPRESENTATION === 'web';

const augmentTrackParamsWithFunnyStuff = () => {
  if (CONFIG.REPRESENTATION === 'web') {
    /**
     * Custom trackParams "Mehr zum Thema" vs. "Passend zum Thema"
     */
    let $teaser = $('.article-teaser');
    if ($teaser.length) {
      $teaser.each((index, element) => {
        const $element = $(element);
        let reffererTrack = $element.attr('data-referrer-track');
        reffererTrack =
          reffererTrack +
          '&srg_mod4=' +
          $('meta[name="srf:content:id"]').attr('content');
        $element.attr('data-referrer-track', reffererTrack);
      });
    }
  }
};

const setCookieForComscore = (cName, cValue, expDays) => {
  let date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + date.toUTCString();
  document.cookie =
    cName +
    '=' +
    cValue +
    '; ' +
    expires +
    '; path=/; domain=' +
    CONFIG.BUSINESS_UNIT +
    '.ch';
};
export function init() {
  if (CONFIG.ANALYTICS_ENABLED) {
    if (!isWeb()) {
      //these modules need to be present on app start
      new ModuleMeasurement();
      new DownloadMeasurement();
      new InteractionMeasurement();
      new SrgElectionWidgetTracking();
      new ClickMeasurement();
    }

    if (CONFIG.KAMELEOON_ENABLED) {
      //initialize kameleeon without consent, tracking will be finetuned once consent is established
      KameleoonInit();
    }

    // these trackers cannot be stopped, they don't require consent, apparently. Yay for them.
    activateByTrackerId('webtrekk');
    activateByTrackerId('comscore');
    activateByTrackerId('udp');
    activateByTrackerId('chartbeat');

    // activate trackers if consent is given
    onEvent({
      eventName: [ACTIVATE_TRACKER],
      eventHandler: (event) => {
        activateByTrackerId(event.detail.trackerId);
      },
    });

    // deactivate trackers if consent is removed
    onEvent({
      eventName: [DISABLE_TRACKER],
      eventHandler: (event) => {
        deactivateByTrackerId(event.detail.trackerId);
      },
    });
    augmentTrackParamsWithFunnyStuff();
  }
}
