import ResizeListener from '@fec/frontend/foundation/client/resize-listener';
import { getConfig } from '@fec/assets/js/utils/config';
import {
  onEvent,
  SHOW_HIDDEN_TEASER,
  STYLES_LOADED,
} from '@fec/assets/js/utils/event';

const IMAGE_PROVIDERS = {
  ROKKA: 'rokka',
  IL: 'il',
  TVP: 'tvp',
};

// Definitions of image stacks/widths
// PLEASE make sure, that
// - they are ordered so that the smallest area is first and the largest area is last <-- READ THIS! 🚩🚩🚩🚩🚩
// - the width and height correspond to the ratio
// - the area is the multiplication of the width times the height
const ROKKA_STACKS = [
  {
    id: '160w',
    width: 160,
    ratio: 16 / 9,
    height: 90,
    area: 14400,
  },
  {
    id: 'square_160w',
    width: 160,
    ratio: 1,
    height: 160,
    area: 25600,
  },
  {
    id: 'square_240w',
    width: 240,
    ratio: 1,
    height: 240,
    area: 57600,
  },
  {
    id: '320w',
    width: 320,
    ratio: 16 / 9,
    height: 180,
    area: 57600,
  },
  {
    id: 'square_small',
    width: 320,
    ratio: 1,
    height: 320,
    area: 102400,
  },
  {
    id: '480w',
    width: 480,
    ratio: 16 / 9,
    height: 270,
    area: 129600,
  },
  {
    id: '640w',
    width: 640,
    ratio: 16 / 9,
    height: 360,
    area: 230400,
  },
  {
    id: 'square_medium',
    width: 640,
    ratio: 1,
    height: 640,
    area: 409600,
  },
  {
    id: '960w',
    width: 960,
    ratio: 16 / 9,
    height: 540,
    area: 518400,
  },
  {
    id: 'square_large',
    width: 800,
    ratio: 1,
    height: 800,
    area: 640000,
  },
  {
    id: '1280w',
    width: 1280,
    ratio: 16 / 9,
    height: 720,
    area: 921600,
  },
];
const TVP_STACKS = [
  { id: '160ws', width: 160, ratio: 16 / 9, height: 90, area: 14400 },
  { id: 'square_160w', width: 160, ratio: 1, height: 160, area: 25600 },
  { id: 'square_240w', width: 240, ratio: 1, height: 240, area: 57600 },
  { id: '320ws', width: 320, ratio: 16 / 9, height: 180, area: 57600 },
  { id: '480ws', width: 480, ratio: 16 / 9, height: 270, area: 129600 },
  { id: '640ws', width: 640, ratio: 16 / 9, height: 360, area: 230400 },
  { id: '960ws', width: 960, ratio: 16 / 9, height: 540, area: 518400 },
  { id: '1280ws', width: 1280, ratio: 16 / 9, height: 720, area: 921600 },
];
const IL_WIDTHS = [240, 320, 480, 720, 960, 1920];

const config = getConfig() ?? {};
const ROKKA_BASE_URL = config['ROKKA_BASE_URL'] ?? '';
const IL_BASE_URL = config['IL_IMAGE_SERVICE_BASE_URL'] ?? '';
const TVP_BASE_URL = 'https://www.srf.ch/static/programm/tv/images';

const RATIO_TOLERANCE = 0.05;
// get pixel ratio from device but don't go overboard (max 2x density)
const DEVICE_PIXEL_RATIO = Math.min(
  Math.round(window.devicePixelRatio || 1.0),
  2,
);
// Getting the best stack is somewhat complex, and many of our images have very
// similar dimensions, so save the assignment of their sizes to the used stack.
const assignmentList = {};

function saveAssignment(provider, width, height, value) {
  assignmentList[`${provider}/${width}/${height}`] = value;
}

function getAssignment(provider, width, height) {
  const value = assignmentList[`${provider}/${width}/${height}`];
  return value ?? false;
}

function generateSource(url, mimeType) {
  const newSource = document.createElement('SOURCE');
  newSource.srcset = url;
  newSource.type = mimeType;
  return newSource;
}

function getLargestStackByProvider(provider) {
  switch (provider) {
    case IMAGE_PROVIDERS.ROKKA:
      return ROKKA_STACKS.at(-1).id;
    case IMAGE_PROVIDERS.IL:
      return IL_WIDTHS.at(-1);
    case IMAGE_PROVIDERS.TVP:
      return TVP_STACKS.at(-1).id;
  }
}

export function getLargestJpegUrl(image) {
  let { id, provider } = image;
  if (!provider) {
    return;
  }

  const largestStack = getLargestStackByProvider(provider);
  const imgUrls = getUrls(provider, id, largestStack);

  if (imgUrls['image/jpeg']) {
    return imgUrls['image/jpeg'];
  }
}

export function getUrls(imageProvider, imageId, imageVersion) {
  if (imageProvider === IMAGE_PROVIDERS.ROKKA) {
    return {
      'image/webp': `${ROKKA_BASE_URL}/${imageVersion}/o-dpr-${DEVICE_PIXEL_RATIO}/${imageId}.webp`,
      'image/jpeg': `${ROKKA_BASE_URL}/${imageVersion}/o-dpr-${DEVICE_PIXEL_RATIO}/${imageId}.jpg`,
    };
  } else if (imageProvider === IMAGE_PROVIDERS.TVP) {
    // TVP only supports jpeg, as far as is known
    return {
      'image/jpeg': `${TVP_BASE_URL}/${imageVersion}/${imageId}.jpg`,
    };
  } else if (imageProvider === IMAGE_PROVIDERS.IL) {
    if (imageId.includes('images/?imageUrl=')) {
      // img was already normalized, no need to add il_base_url (but we need to change the hardcoded format)
      return {
        'image/webp': `${imageId}&width=${imageVersion}`.replace(
          '&format=jpg&',
          '&format=webp&',
        ),
        'image/jpeg': `${imageId}&width=${imageVersion}`,
      };
    } else {
      // TODO: remove once all images are normalized
      return {
        'image/webp': `${IL_BASE_URL}/?imageUrl=${imageId}&format=webp&width=${imageVersion}`,
        'image/jpeg': `${IL_BASE_URL}/?imageUrl=${imageId}&format=jpg&width=${imageVersion}`,
      };
    }
  }
}

function addSources(imageProvider, imageElement, imageVersion) {
  const { imageId } = imageElement.dataset;

  const imgUrls = getUrls(imageProvider, imageId, imageVersion);
  const newSources = [];

  Object.entries(imgUrls).forEach(([mimeType, url]) => {
    newSources.push(generateSource(url, mimeType));
  });

  imageElement.querySelector('picture').prepend(...newSources);
}

// get the smallest stack where the image fits into and where the ratio is
// acceptably similar. Fallback: largest stack.
function getStackToUse(provider, stackList, width, height) {
  // shortcut: if we've handled this case before, we know the best stack :)
  const prevStack = getAssignment(provider, width, height);
  if (prevStack) {
    return prevStack;
  }

  const imgRatio = width / height;
  // we can use a stack, if our image's width is larger than the stack's image,
  // same with the height and if the ratio is approximately the same.
  // (16/9 is 1.77777... and a 16:9 image with the dimensions 580/326.26 has a
  // ratio of 1.7777232882, so it's no exact match --> hence the tolerance)
  const smallestPossibleStack = stackList.find((stack) => {
    const widthOK = stack.width >= width;
    const heightOK = stack.height >= height;
    const hasSameRatio = Math.abs(stack.ratio - imgRatio) < RATIO_TOLERANCE;

    return widthOK && heightOK && hasSameRatio;
  });

  // if we have a very large area to cover, we take the largest stack we can:
  const chosenStack = smallestPossibleStack ?? stackList.at(-1);

  saveAssignment(provider, width, height, chosenStack);

  return chosenStack;
}

function getWidthToUse(width) {
  // shortcut: if we've handled this case before, we know the best width :)
  const prevWidth = getAssignment(IMAGE_PROVIDERS.IL, width, '');
  if (prevWidth) {
    return prevWidth;
  }

  const fixedClientWidth = Math.floor(width * DEVICE_PIXEL_RATIO);
  const minWidth = IL_WIDTHS.find((w) => w >= fixedClientWidth);

  const chosenWidth = minWidth ?? IL_WIDTHS.at(-1);

  saveAssignment(IMAGE_PROVIDERS.IL, width, '', chosenWidth);

  return chosenWidth;
}

export function getVersionToLoad(
  imageProvider,
  width,
  height,
  largestLoadedVersion,
) {
  let versionToLoad;

  // IL uses width groups instead of stacks. The logic is different but simpler
  if (imageProvider === IMAGE_PROVIDERS.IL) {
    const widthToUse = getWidthToUse(width);

    // don't load a new image if a larger image was loaded already
    if (
      largestLoadedVersion &&
      Number.parseInt(largestLoadedVersion, 10) >= widthToUse
    ) {
      return;
    }

    versionToLoad = widthToUse.toString(10);
  } else {
    // Rokka and TVP use stacks
    const stackList =
      imageProvider === IMAGE_PROVIDERS.ROKKA ? ROKKA_STACKS : TVP_STACKS;

    const stackToLoad = getStackToUse(
      imageProvider,
      stackList,
      Math.floor(width),
      Math.floor(height),
    );

    // if a largest loaded version was provided, make sure we don't load a smaller image
    if (largestLoadedVersion) {
      // get stack of the largest loaded stack from the data attribute that we saved before
      const largestLoadedStack = stackList.find(
        ({ id }) => id === largestLoadedVersion,
      );
      // don't load a new image if a larger stack of the same ratio (!) was loaded already
      if (
        largestLoadedStack?.width >= stackToLoad.width &&
        largestLoadedStack?.height >= stackToLoad.height &&
        largestLoadedStack?.ratio === stackToLoad.ratio
      ) {
        return;
      }
    }
    versionToLoad = stackToLoad.id;
  }

  return versionToLoad;
}

function handleImage(image, index, imageDimensionsList) {
  const { imageProvider, largestLoadedVersion } = image.dataset;

  // get the dimensions that the image should occupy (for performance reasons not directly from the image
  // via getBoundingClientRect() but from the imageDimensionsList array)
  const imageDimension = imageDimensionsList[index];
  const width = imageDimension?.width;
  const height = imageDimension?.height;

  // ignore images that occupy no space
  if (width === 0 || height === 0) {
    return;
  }

  const versionToLoad = getVersionToLoad(
    imageProvider,
    width,
    height,
    largestLoadedVersion,
  );

  // if no version to load is returned, we don't need to do anything (i.e. largest loaded version is already sufficient)
  if (!versionToLoad) {
    return;
  }

  // generate and add the source(s) to the image element
  addSources(imageProvider, image, versionToLoad);
  // ...and save it for the next pass
  image.dataset.largestLoadedVersion = versionToLoad;

  // let the image know that the new sources are to be loaded lazily/eagerly, too!
  image.querySelector('img').loading = index > 5 ? 'lazy' : 'eager';
}

function updateImages() {
  const imageDimensionsList = [];

  const imagesToHandle = document.querySelectorAll(
    '.js-image-ng.js-image-ng-lazyload',
  );

  // reading the image dimensions before changing the DOM improves the performance about
  // 20 (!) times because getBoundingClientRect() causes reflow and adding source elements
  // to the DOM forces style/layout recalculations
  // see: https://gist.github.com/paulirish/5d52fb081b3570c81e3a#getting-box-metrics
  imagesToHandle.forEach((image, index) => {
    const { width, height } = image.getBoundingClientRect();
    imageDimensionsList[index] = {
      width: width,
      height: height,
    };
  });

  // *after* we have the dimensions, we can go through the images and load the "best" image
  imagesToHandle.forEach((image, index) => {
    if (Object.values(IMAGE_PROVIDERS).includes(image.dataset.imageProvider)) {
      handleImage(image, index, imageDimensionsList);
    } else {
      // Unknown image type or no image present
    }
  });
}

export function init() {
  // load the best image size

  // - on load
  updateImages();

  // - after resizing
  ResizeListener.subscribeDebounced(updateImages);

  // - when new teasers were added/revealed
  onEvent({
    eventName: SHOW_HIDDEN_TEASER,
    eventHandler: updateImages,
  });

  // - when the styles were loaded AFTER this code was executed
  onEvent({
    eventName: STYLES_LOADED,
    eventHandler: updateImages,
  });
}
