import { Tools } from './Tools';
// keep multiple Referrers because multiple Browser Tabs use the same LocalStorage
export const REFERRER_STORAGE = 'srf:analytics:referer';
const REFERRER_STORAGE_EXPIRY = 3600; // 1 hour
// singleton pattern: client code calls with "new Webtrekk"
let instance = null;

export class Webtrekk extends Tools {
  constructor(options) {
    super();
    if (instance) {
      return instance;
    }

    this.options = options;
    this.location = document.location.href;
    this.referrerData = {};
    window.webtrekkConfig = this.options.config;
    window.wtSmart = window.wtSmart || [];
    // set initial tracking config

    window.wtSmart.push(function (wtSmart) {
      wtSmart.init.set({
        trackId: window.webtrekkConfig.trackId,
        trackDomain: window.webtrekkConfig.trackDomain,
        cookie: '3',
      });
      wtSmart.campaign.data.set({
        mediaCode: ['mc', 'wt_mc', 'wt_mc_o'],
        oncePerSession: true,
      });
    });

    this.handleReferrerData();

    this.enrichOptions();
    this.initVendorLib();
    this.fillSmartPixel();
    this.init();
    instance = this;
  }
  init() {
    this.loadExternalJs(
      '/assets/webpack/webtrekk-smart-pixel/smart-pixel.min.js',
    );
  }

  handleReferrerData() {
    let referrerData = this.getData(REFERRER_STORAGE);
    let now = Math.round(new Date() / 1000);
    // cleanup referrerData if needed
    if (referrerData) {
      referrerData = JSON.parse(referrerData);
      for (let location in referrerData) {
        let entry = referrerData[location];
        if (entry.ts && now - entry.ts <= REFERRER_STORAGE_EXPIRY) {
          this.referrerData[location] = entry;
        }
      }
    }
    // add current location with stats data to make it useable for the next page
    this.referrerData[this.location] = {
      contentId: this.options.pageconfig.contentId,
      ts: now,
    };

    this.setData(REFERRER_STORAGE, JSON.stringify(this.referrerData));
  }

  enrichOptions() {
    // Custom Page Parameter 31 (Days since publication)
    this.options.customParameter[31] = this.getDaysSincePublished(
      this.options.customParameter[33],
    );
    //custom parameter to get the requested url in webtrekk
    this.options.customParameter[12] = document.location.href;
    // Custom Page Parameter 24-27 (module tracking)
    let modData = JSON.parse(this.getData('srf:analytics:mod'));
    if (modData) {
      this.options.customParameter[24] = this.getModData(modData, 'mod1');
      this.options.customParameter[25] = this.getModData(modData, 'mod2');
      this.options.customParameter[26] = this.getModData(modData, 'mod3');
      this.options.customParameter[27] = this.getModData(modData, 'mod4');
      this.setData('srf:analytics:mod:udp', JSON.stringify(modData));
      this.removeData('srf:analytics:mod');
    }

    //Custom Page Parameter 18 (user settings -> darkmode)
    let userSettings;
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      userSettings = 'dark_mode(1)';
    } else {
      userSettings = 'dark_mode(0)';
    }
    this.options.customParameter[18] = userSettings;

    // statistics overwrites and additions in ESIs
    let $esiStatsDataSets = $('.js-esi-statistics-overwrites').toArray();
    if ($esiStatsDataSets.length > 0) {
      $esiStatsDataSets.forEach((esiStatsData) => {
        let dataSet = $(esiStatsData).data('analytics-json');
        for (const param of Object.keys(dataSet)) {
          this.options.customParameter[param] = dataSet[param];
        }
      });
    }
  }

  initVendorLib() {
    window.wtSmart.push((wtSmart) => {
      // set initial tracking config
      try {
        wtSmart.page.data.set({
          name: this.options.pageconfig.contentId,
          parameter: this.options.customParameter,
          category: this.options.contentGroup,
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    });
  }

  fillSmartPixel() {
    window.wtSmart.push((wtSmart) => {
      // set initial tracking config
      try {
        wtSmart.page.data.set({
          name: this.options.pageconfig.contentId,
          parameter: this.options.customParameter,
          category: this.options.contentGroup,
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    });
  }
  sendInfo(data = null) {
    if (data) {
      window.wtSmart.push((wtSmart) => {
        wtSmart.action.data.set({
          name: data.linkId,
          parameter: data.customClickParameter,
        });
        wtSmart.track();
      });
    } else {
      window.wtSmart.push(function (wtSmart) {
        wtSmart.track();
      });
    }
  }
}
